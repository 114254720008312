<script>
export default {};
</script>

<template>
<div class="card">
    <div class="card-body">
        <a href="" class="p-0 float-right mb-3">Export <i class="mdi mdi-download ml-1"></i></a>
        <h4 class="header-title mt-1">Engagement Overview</h4>

        <div class="table-responsive mb-0">
            <table class="table table-sm table-centered mb-0 font-14">
                <thead class="thead-light">
                    <tr>
                        <th>Duration (Secs)</th>
                        <th style="width: 30%;">Sessions</th>
                        <th style="width: 30%;">Views</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>0-30</td>
                        <td>2,250</td>
                        <td>4,250</td>
                    </tr>
                    <tr>
                        <td>31-60</td>
                        <td>1,501</td>
                        <td>2,050</td>
                    </tr>
                    <tr>
                        <td>61-120</td>
                        <td>750</td>
                        <td>1,600</td>
                    </tr>
                    <tr>
                        <td>121-240</td>
                        <td>540</td>
                        <td>1,040</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- end table-responsive-->
    </div>
    <!-- end card-body-->
</div>
<!-- end card-->
</template>
